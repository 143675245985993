import { authorization, request } from "./core";

export function fetchBookList(params) {
  return request({
    method: "get",
    url: "/books",
    params,
    headers: authorization(),
  });
}

export function fetchBookPublishers() {
  return request({
    method: "get",
    url: "/books/publishers",
    headers: authorization(),
  });
}

export function fetchBookAuthors() {
  return request({
    method: "get",
    url: "/books/authors",
    headers: authorization(),
  });
}

export function fetchBookTags() {
  return request({
    method: "get",
    url: "/books/tags",
    headers: authorization(),
  });
}

export function fetchBookById(bookId) {
  return request({
    method: "get",
    url: `/books/${bookId}`,
    headers: authorization(),
  });
}

export function addBook(data) {
  return request({
    method: "post",
    url: "/books",
    headers: authorization(),
    data,
  });
}

export function updateBookById(bookId, data) {
  return request({
    method: "patch",
    url: `/books/${bookId}`,
    headers: authorization(),
    data,
  });
}

export function uploadBookImage(bookId, data) {
  return request({
    method: "post",
    url: `/books/${bookId}/image`,
    headers: authorization(),
    data,
  });
}

export function deactivateBookById(bookId, data) {
  return request({
    method: "patch",
    url: `/books/${bookId}/deactivate`,
    headers: authorization(),
    data,
  });
}
