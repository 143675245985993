import "@mdi/font/css/materialdesignicons.css";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import utc from "dayjs/plugin/utc";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

dayjs.locale("ja");
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

Vue.use(Buefy, {
  defaultFieldLabelPosition: "on-border",
});
Vue.config.productionTip = process.env.NODE_ENV !== "production";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
