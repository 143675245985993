import { authorization, request } from "./core";

export function fetchWorkExperienceList() {
  return request({
    method: "get",
    url: `/work_experiences`,
    headers: authorization(),
  });
}

export function addWorkExperience(data) {
  return request({
    method: "post",
    url: `/work_experiences`,
    headers: authorization(),
    data,
  });
}

export function updateWorkExperienceById(workExperienceId, data) {
  return request({
    method: "patch",
    url: `/work_experiences/${workExperienceId}`,
    headers: authorization(),
    data,
  });
}

export function removeWorkExperienceById(workExperienceId) {
  return request({
    method: "delete",
    url: `/work_experiences/${workExperienceId}`,
    headers: authorization(),
  });
}
