import { authorization, download, request } from "./core";

export function fetchAdvanceSheetList() {
  return request({
    method: "get",
    url: `/advance_sheets`,
    headers: authorization(),
  });
}

export function downloadAdvanceSheetXlsx(params) {
  return download({
    method: "get",
    url: `/advance_sheets/all`,
    headers: authorization(),
    params,
  });
}

export function addAdvanceSheet(data) {
  return request({
    method: "post",
    url: `/advance_sheets`,
    headers: authorization(),
    data,
  });
}

export function fetchAdvanceSheetById(advanceSheetId) {
  return request({
    method: "get",
    url: `/advance_sheets/${advanceSheetId}`,
    headers: authorization(),
  });
}

export function updateAdvanceSheetById(advanceSheetId, data) {
  return request({
    method: "patch",
    url: `/advance_sheets/${advanceSheetId}`,
    headers: authorization(),
    data,
  });
}

export function addAdvance(advanceSheetId, data) {
  return request({
    method: "post",
    url: `/advance_sheets/${advanceSheetId}/advances`,
    headers: authorization(),
    data,
  });
}

export function updateAdvanceById(advanceId, data) {
  return request({
    method: "patch",
    url: `/advances/${advanceId}`,
    headers: authorization(),
    data,
  });
}

export function removeAdvanceById(advanceId) {
  return request({
    method: "delete",
    url: `/advances/${advanceId}`,
    headers: authorization(),
  });
}
