import dayjs from "dayjs";
import Vue from "vue";
import Vuex from "vuex";
import { fetchHolidays } from "../api";
import cache from "../cache";
import admin from "./modules/admin";
import auth from "./modules/auth";
import my from "./modules/my";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    loading: false,
    error: null,
    holidays: new Set(),
    serviceStatus: 200,
  },
  actions: {
    clear({ commit }) {
      commit("clear");
      commit("admin/clear");
      commit("my/clear");
    },
    async fetchHolidays({ commit }) {
      let holidays = cache.get("holidays");
      if (holidays === null || holidays.length === 0) {
        const response = await fetchHolidays();
        holidays = response.holidays.map((v) => v.date);
        cache.set("holidays", holidays, {
          expiresAt: dayjs().endOf("year").valueOf(),
        });
      }
      commit("setHolidays", holidays);
    },
  },
  mutations: {
    clear(state) {
      state.holidays = new Set();
    },
    setHolidays(state, holidays) {
      state.holidays = new Set(holidays);
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
    setServiceStatus(state, status) {
      state.serviceStatus = status;
    },
  },
  modules: { auth, admin, my },
});
