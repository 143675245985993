import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    /**
     * 参照APIの発行
     *
     * エラーメッセージ出力とローディング状態の制御付き
     *
     * @param {string | Function} action 発効するアクション、または関数
     * @param {object?} param アクションに渡すパラメータ
     */
    async fetch(action, param) {
      try {
        this.loading = true;
        if (typeof action === "function") {
          await action();
        } else {
          await this.$store.dispatch(action, param);
        }
        this.$store.commit("setServiceStatus", 200, { root: true });
      } catch (e) {
        if (e.status && e.status === 401) {
          this.$router.push({ name: "login" });
        }
        let message;
        if (e.data === undefined) {
          message = e.message;
        } else {
          if (e.data instanceof Blob) {
            message = JSON.parse(await e.data.text()).detail;
          } else {
            message = e.data.detail;
          }
        }
        this.$buefy.toast.open({
          duration: 5000,
          message,
          position: "is-top",
          type: "is-danger",
        });
        if (e.status && [403, 500].includes(e.status)) {
          this.$store.commit("setServiceStatus", e.status, { root: true });
        }
        throw e;
      } finally {
        this.loading = false;
      }
    },
  },
};

export function parseDateField(value) {
  return value === undefined ? null : dayjs(value).toDate();
}

export function parseTimeField(value) {
  return value === undefined
    ? null
    : dayjs(value, ["HH:mm", "HH:mm:ss"]).toDate();
}
