/**
 * キャッシュにデータを格納する
 * @param {string} key キャッシュのキー
 * @param {object} data キャッシュデータ
 * @param {object} option オプション(maxAgeとexpiresAtはどちらか一方だけ指定可能)
 * @param {number} option.maxAge キャッシュの有効期間[msec]
 * @param {number} option.expiresAt キャッシュの有効期限(Unixタイムスタンプ[msec])
 */
function set(key, data, option) {
  if (
    key === undefined ||
    data === undefined ||
    (option !== undefined && typeof option !== "object")
  ) {
    throw new Error();
  }
  localStorage.setItem(`atago-portal:${key}:data`, JSON.stringify(data));
  if (typeof option.maxAge === "number") {
    localStorage.setItem(
      `atago-portal:${key}:expires-at`,
      Date.now() + option.maxAge
    );
  } else if (typeof option.expiresAt === "number") {
    localStorage.setItem(`atago-portal:${key}:expires-at`, option.expiresAt);
  }
}

/**
 * キャッシュからデータを取得する
 * @param {string} key キャッシュのキー
 * @returns {object | null} キャッシュデータ。有効期限が切れている場合はnullが返される
 */
function get(key) {
  if (key === undefined) {
    throw new Error();
  }

  const expiresAt = localStorage.getItem(`atago-portal:${key}:expires-at`);
  if (expiresAt !== null && expiresAt <= Date.now()) {
    // already expired
    return null;
  }

  const data = localStorage.getItem(`atago-portal:${key}:data`);
  if (data === null) {
    return null;
  } else {
    return JSON.parse(data);
  }
}

export default {
  set,
  get,
};
