import { authorization, request } from "./core";

export function fetchMe() {
  return request({
    method: "get",
    url: `/me`,
    headers: authorization(),
  });
}

export function updateMe(data) {
  return request({
    method: "patch",
    url: `/me`,
    headers: authorization(),
    data,
  });
}
