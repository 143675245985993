import { authorization, request } from "./core";

export function fetchRentalList() {
  return request({
    method: "get",
    url: "/rentals",
    headers: authorization(),
  });
}

export function addRental(data) {
  return request({
    method: "post",
    url: "/rentals",
    headers: authorization(),
    data,
  });
}

export function updateRentalById(rentalId, data) {
  return request({
    method: "patch",
    url: `/rentals/${rentalId}`,
    headers: authorization(),
    data,
  });
}
