import dayjs from "dayjs";
import rfdc from "rfdc";

export default {
  data() {
    return {
      storing: false,
      errors: {},
    };
  },
  methods: {
    async store(action, form) {
      try {
        this.storing = true;
        this.errors = {};
        if (typeof action === "function") {
          await action();
        } else {
          await this.$store.dispatch(action, form);
        }
        this.$buefy.toast.open({
          duration: 2000,
          message: "正常に登録されました",
          position: "is-top",
          type: "is-success",
        });
        this.$store.commit("setServiceStatus", 200, { root: true });
      } catch (e) {
        if (e.status && e.status === 401) {
          this.$router.push({ name: "login" });
        }
        let message = "";
        if (e.status && e.status === 422) {
          this.errors = e.data.detail
            .map((v) => ({
              field: v.loc.slice(1).join("."),
              message: v.msg,
              type: v.type,
            }))
            .reduce((a, c) => {
              const { field, ...val } = c;
              if (a[field]) {
                a[field].push(val);
              } else {
                a[field] = [val];
              }
              return a;
            }, {});
          message = "入力内容にエラーがあります";
        } else if (e.status && [403, 500].includes(e.status)) {
          this.$store.commit("setServiceStatus", e.status, { root: true });
        } else {
          message = e.data ? e.data.detail : e.message;
        }

        if (message !== "") {
          this.$buefy.toast.open({
            duration: 10000,
            message,
            position: "is-top",
            type: "is-danger",
          });
        }
        throw e;
      } finally {
        this.storing = false;
      }
    },
    _getErrors(path) {
      if (/\*/.test(path)) {
        const pattern = new RegExp(
          path.replaceAll(".", "\\.").replaceAll("*", ".+")
        );
        const field = Object.keys(this.errors).find((v) => pattern.test(v));
        return field ? this.errors[field] : undefined;
      } else {
        return this.errors[path];
      }
    },
    hasError(field) {
      return this._getErrors(field) ? "is-danger" : "";
    },
    message(field) {
      const errors = this._getErrors(field);
      return errors ? errors.map((v) => v.message) : [];
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.errors = {};
    next();
  },
};

export function formatDateField(value) {
  return value instanceof Date ? dayjs(value).format("YYYY-MM-DD") : value;
}

export function formatTimeField(value) {
  return value instanceof Date ? dayjs(value).format("HH:mm") : value;
}

export const clone = rfdc();
