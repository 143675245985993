import { authorization, request } from "./core";

export function grantPaidAnnualLeave(data) {
  return request({
    method: "post",
    url: `/paid_annual_leaves`,
    headers: authorization(),
    data,
  });
}
