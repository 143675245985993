import dayjs from "dayjs";

function isOverlap(breakTime1, breakTime2) {
  if (breakTime1.id === breakTime2.id) {
    return false;
  }

  if (
    breakTime1.begin_at === null ||
    breakTime1.end_at === null ||
    breakTime2.begin_at === null ||
    breakTime2.end_at === null
  ) {
    return false;
  }

  const latestStart =
    breakTime1.begin_at >= breakTime2.begin_at
      ? breakTime1.begin_at
      : breakTime2.begin_at;

  const earliestEnd =
    breakTime1.end_at <= breakTime2.end_at
      ? breakTime1.end_at
      : breakTime2.end_at;

  const interval = dayjs(earliestEnd).diff(dayjs(latestStart));
  return interval > 0;
}

export function hasAnyOverlappedBreakTimes(attendance) {
  for (const bt1 of attendance.break_times) {
    for (const bt2 of attendance.break_times) {
      if (isOverlap(bt1, bt2)) {
        return true;
      }
    }
  }
  return false;
}

export function hasAnyOverflows(attendance) {
  for (const bt of attendance.break_times) {
    if (bt.begin_at === null || bt.end_at === null) {
      continue;
    }
    if (
      bt.begin_at < attendance.clockin_time ||
      attendance.clockout_time < bt.end_at
    ) {
      return true;
    }
  }
  return false;
}

export function hasInvalidBreakTimes(attendance) {
  for (const bt of attendance.break_times) {
    if (bt.begin_at === null || bt.end_at === null) {
      continue;
    }
    if (bt.begin_at >= bt.end_at) {
      return true;
    }
  }
  return false;
}

function getWorkHours(attendance) {
  return dayjs(attendance.clockout_time).diff(
    attendance.clockin_time,
    "minutes"
  );
}

function getBreakTimeHours(attendance) {
  return attendance.break_times
    .filter((v) => v.begin_at !== null && v.end_at !== null)
    .map((v) => dayjs(v.end_at).diff(v.begin_at, "minutes"))
    .reduce((a, c) => a + c, 0);
}

export function hasTooFewBreakTimes(attendance) {
  return (
    getWorkHours(attendance) > 6 * 60 && getBreakTimeHours(attendance) < 60
  );
}

export function hasTooMuchBreakTimes(attendance) {
  return (
    getWorkHours(attendance) <= 6 * 60 && getBreakTimeHours(attendance) > 0
  );
}
