<template>
  <div id="app">
    <router-view v-if="serviceStatus === 200" />
    <unavailable v-if="serviceStatus === 500" />
    <forbidden v-if="serviceStatus === 403" />
  </div>
</template>

<script>
import Forbidden from "./views/Forbidden";
import Unavailable from "./views/Unavailable";
export default {
  name: "App",
  components: { Forbidden, Unavailable },
  computed: {
    serviceStatus() {
      return this.$store.state.serviceStatus;
    },
  },
};
</script>

<style>
dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
dt {
  width: 30%;
  font-weight: bold;
}
dd {
  width: 70%;
}
</style>
