export const PAGE_TITLES = {
  menu: "メニュー",
  settings: "設定",
  attendanceSheet: "勤務表",
  latestAttendanceSheet: "勤務表",
  advanceSheet: "立替金申請",
  latestAdvanceSheet: "立替金申請",
  resume: "経歴書",
  users: "社員一覧",
  adminAttendanceSheet: "勤務表",
  adminAdvanceSheet: "立替金申請",
  books: "書籍検索",
  rentals: "貸出履歴",
};

export const CONTENT = {
  WORK: { value: 1, label: "出勤", type: "is-success" },
  ABSENCE: { value: 2, label: "欠勤", type: "is-danger" },
  PAID_DAY_OFF: { value: 3, label: "年休", type: "is-warning" },
  SUMMER_HOLIDAYS: { value: 4, label: "夏休", type: "is-info" },
  COMPENSATORY_DAY_OFF: { value: 5, label: "明休", type: "is-warning" },
  SUBSTITUTE_DAY_OFF: { value: 6, label: "振休", type: "is-warning" },
  CC_LEAVE: { value: 7, label: "慶弔", type: "is-warning" },
};

export const CONTENT_ON_HOLIDAY = {
  WORK: { value: 1, label: "休出", type: "is-success is-light" },
  ABSENCE: { value: 2, label: "定休", type: "is-danger is-light" },
};

export const ROLE = {
  ADMIN: { value: 1, label: "管理者" },
  MEMBER: { value: 2, label: "メンバー" },
};

export const SEX = {
  MALE: { value: 1, label: "男性" },
  FEMALE: { value: 2, label: "女性" },
};

export const MARITAL_STATUS = {
  UNMARRIED: { value: 1, label: "未婚" },
  MARRIED: { value: 2, label: "既婚" },
};

export const ACCOUNTS_CODE = {
  OTHER: { value: 0, label: "その他" },
  TRAVEL_TRANSPORTATION: { value: 1, label: "旅費交通費" },
  ENTERTAINMENT_SOCIALIZING: { value: 2, label: "接待交際費" },
  MEETING: { value: 3, label: "会議費" },
  ADVERTISING: { value: 4, label: "広告宣伝費" },
  COMMUNICATION_EXPENSE: { value: 5, label: "通信費" },
  TAX_DUES: { value: 6, label: "租税公課" },
  OFFICE_SUPPLY: { value: 7, label: "事務用品費" },
  NEWSPAPER_BOOK: { value: 8, label: "新聞図書費" },
  CONSUMABLE: { value: 9, label: "消耗品費" },
};

export const PAID_ANNUAL_LEAVE_CHANGE = {
  CARRY_OVER: { value: 0, label: "繰越" },
  GRANT: { value: 1, label: "付与" },
  TAKE: { value: 2, label: "消化" },
  PRESCRIPTION: { value: 3, label: "時効消滅" },
};

export const ROLE_CODE = {
  MEMBER: { value: 1, label: "担当者", type: "is-success" },
  MANAGER: { value: 2, label: "管理者", type: "is-warning" },
};

export const EXPERIENCED_TASK = {
  ANALYSIS: { value: 1, label: "要求分析/システム分析", type: "is-primary" },
  REQUIREMENTS_DEFINITION: { value: 2, label: "仕様検討", type: "is-primary" },
  BASIC_DESIGN: { value: 3, label: "概要設計", type: "is-info" },
  DETAIL_DESIGN: { value: 4, label: "詳細設計", type: "is-info" },
  IMPLEMENTATION: { value: 5, label: "製造", type: "is-success" },
  UNIT_TEST: { value: 6, label: "単体テスト", type: "is-warning" },
  INTEGRATION_TEST: { value: 7, label: "結合テスト", type: "is-warning" },
  SYSTEM_TEST: { value: 8, label: "システムテスト", type: "is-warning" },
  EVALUATION_TEST: { value: 9, label: "品質評価テスト", type: "is-warning" },
  INTRODUCTION: { value: 10, label: "導入支援", type: "is-danger" },
  MAINTENANCE: { value: 11, label: "保守", type: "is-danger" },
  OTHERS: { value: 12, label: "その他", type: "is-light" },
};

export const PROJECT_COMPONENT_ATTRIBUTE = {
  OS: { value: 1, label: "OS" },
  LANG: { value: 2, label: "言語" },
  DB: { value: 3, label: "DB" },
  TOOL: { value: 4, label: "ツール" },
};

export const C_CODE_TARGET = {
  0: "一般",
  1: "教養",
  2: "実用",
  3: "専門",
  5: "婦人",
  6: "学参I(小中)",
  7: "学参II(高校)",
  8: "児童",
  9: "雑誌扱い",
};

export const C_CODE_FORMAT = {
  0: "単行本",
  1: "文庫",
  2: "新書",
  3: "全集・双書",
  4: "ムック・その他",
  5: "事・辞典",
  6: "図鑑",
  7: "絵本",
  8: "磁性媒体など",
  9: "コミック",
};

export const C_CODE_CATEGORY = {
  "00": "総記",
  "01": "百科事典",
  "02": "年鑑・雑誌",
  "04": "情報科学",
  10: "哲学",
  11: "心理(学)",
  12: "倫理(学)",
  14: "宗教",
  15: "仏教",
  16: "キリスト教",
  20: "歴史総記",
  21: "日本歴史",
  22: "外国歴史",
  23: "伝記",
  25: "地理",
  26: "旅行",
  30: "社会科学総記",
  31: "政治-含む国防軍事",
  32: "法律",
  33: "経済・財政・統計",
  34: "経営",
  36: "社会",
  37: "教育",
  39: "民族・風習",
  40: "自然科学総記",
  41: "数学",
  42: "物理学",
  43: "化学",
  44: "天文・地学",
  45: "生物学",
  47: "医学・歯学・薬学",
  50: "工学・工学総記",
  51: "土木",
  52: "建築",
  53: "機械",
  54: "電気",
  55: "電子通信",
  56: "海事",
  57: "採鉱・冶金",
  58: "その他の工業",
  60: "産業総記",
  61: "農林業",
  62: "水産業",
  63: "商業",
  65: "交通・通信",
  70: "芸術総記",
  71: "絵画・彫刻",
  72: "写真・工芸",
  73: "音楽・舞踊",
  74: "演劇・映画",
  75: "体育・スポーツ",
  76: "諸芸・娯楽",
  77: "家事",
  79: "コミックス・劇画",
  80: "語学総記",
  81: "日本語",
  82: "英米語",
  84: "ドイツ語",
  85: "フランス語",
  87: "各国語",
  90: "文学総記",
  91: "日本文学総記",
  92: "日本文学詩歌",
  93: "日本文学、小説・物語",
  95: "日本文学、評論、随筆、その他",
  97: "外国文学小説",
  98: "外国文学、その他",
};

export const BOOKSHELF_ADDRESS = {
  ...Object.fromEntries(
    Array.from(Array(15), (v, k) => {
      const num = String(k + 1).padStart(2, "0");
      const key = `W1-${num}`;
      return [key, { value: key, label: key }];
    })
  ),
  ...Object.fromEntries(
    Array.from(Array(10), (v, k) => {
      const num = String(k + 1).padStart(2, "0");
      const key = `W2-${num}`;
      return [key, { value: key, label: key }];
    })
  ),
  ...Object.fromEntries(
    Array.from(Array(3), (v, k) => {
      const num = String(k + 1).padStart(2, "0");
      const key = `B1-${num}`;
      return [key, { value: key, label: key }];
    })
  ),
};
