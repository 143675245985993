import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Menu from "../views/Menu.vue";
const AttendanceSheet = () => import("../views/AttendanceSheet.vue");
const Login = () => import("../views/Login.vue");
const NotFound = () => import("../views/NotFound.vue");
const Resume = () => import("../views/Resume.vue");
const Settings = () => import("../views/Settings.vue");
const UserList = () => import("../views/UserList.vue");
const AdvanceSheet = () => import("../views/AdvanceSheet.vue");
const BookList = () => import("../views/BookList.vue");
const BookForm = () => import("../components/BookForm.vue");
const RentalList = () => import("../views/RentalList.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "menu",
        component: Menu,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
      },
      {
        path: "attendance_sheets/:year/:month",
        name: "attendanceSheet",
        component: AttendanceSheet,
        props: true,
      },
      {
        path: "attendance_sheets/latest",
        name: "latestAttendanceSheet",
        component: AttendanceSheet,
      },
      {
        path: "advance_sheets/:year/:month",
        name: "advanceSheet",
        component: AdvanceSheet,
        props: true,
      },
      {
        path: "advance_sheets/latest",
        name: "latestAdvanceSheet",
        component: AdvanceSheet,
      },
      {
        path: "resume",
        name: "resume",
        component: Resume,
      },
      {
        path: "users",
        name: "users",
        component: UserList,
      },
      {
        path: "users/:userId/attendance_sheets/:year/:month",
        name: "adminAttendanceSheet",
        component: AttendanceSheet,
        props: true,
      },
      {
        path: "users/:userId/advance_sheets/:year/:month",
        name: "adminAdvanceSheet",
        component: AdvanceSheet,
        props: true,
      },
      {
        path: "books",
        name: "books",
        component: BookList,
        children: [
          {
            path: ":bookId",
            component: BookForm,
            props: true,
            meta: {
              isFormOpen: true,
            },
          },
        ],
      },
      {
        path: "rentals",
        name: "rentals",
        component: RentalList,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const needAuth = to.name !== "login";
  const accessToken =
    localStorage.getItem("atago-portal:accessToken") ??
    sessionStorage.getItem("atago-portal:accessToken");
  if (needAuth && accessToken === null) {
    next({ name: "login" });
  } else if (!needAuth && accessToken !== null) {
    next({ name: "menu" });
  } else {
    next();
  }
});

export default router;
