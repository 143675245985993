<template>
  <div
    class="
      container
      is-flex is-align-items-center is-justify-content-center
      has-background-light
    "
    style="height: 100vh"
  >
    <b-message
      title="403 Forbidden"
      type="is-danger"
      has-icon
      :closable="false"
      style="width: 50vw; min-width: 320px"
    >
      指定されたページを表示する権限がありません
    </b-message>
  </div>
</template>

<script>
export default {
  name: "Forbidden",
};
</script>
