<template>
  <div>
    <b-navbar fixed-top :mobile-burger="false" type="is-primary">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: 'menu' }">
          <img src="/images/brand.png?v=20220414" alt="Atago Portal" />
        </b-navbar-item>
        <b-navbar-item tag="div" class="is-size-4">{{ title }}</b-navbar-item>
      </template>
    </b-navbar>
    <main class="mt-4 mb-6">
      <b-loading v-model="loading" />
      <router-view />
    </main>
  </div>
</template>

<script>
import { PAGE_TITLES } from "../constants";
import store from "../store";

export default {
  name: "Home",
  computed: {
    title() {
      return PAGE_TITLES[this.$route.name];
    },
    loading() {
      return this.$store.state.loading;
    },
    error() {
      return this.$store.state.error;
    },
  },
  watch: {
    error(message) {
      if (message !== null) {
        this.$buefy.toast.open({
          duration: 5000,
          message,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
  },
  async beforeRouteEnter(_to, _from, next) {
    try {
      await store.dispatch("fetchHolidays");
      store.commit("setServiceStatus", 200);
      next();
    } catch (e) {
      if (e.status) {
        store.commit("setServiceStatus", e.status);
        if (e.status === 401) {
          next({ name: "login" });
        }
      }
    }
  },
};
</script>
