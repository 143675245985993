import { authorization, request } from "./core";

export function fetchUserList() {
  return request({
    method: "get",
    url: "/users",
    headers: authorization(),
  });
}

export function fetchAllUsers() {
  return request({
    method: "get",
    url: "/users/all",
    headers: authorization(),
  });
}

export function addUser(data) {
  return request({
    method: "post",
    url: "/users",
    headers: authorization(),
    data,
  });
}

export function fetchUserById(userId) {
  return request({
    method: "get",
    url: `/users/${userId}`,
    headers: authorization(),
  });
}

export function deactivateUserById(userId, data) {
  return request({
    method: "patch",
    url: `/users/${userId}/deactivate`,
    headers: authorization(),
    data,
  });
}

export function addPassCycleByUserId(userId, data) {
  return request({
    method: "post",
    url: `/users/${userId}/cycle`,
    headers: authorization(),
    data,
  });
}

export function updatePassCycleByUserId(userId, data) {
  return request({
    method: "patch",
    url: `/users/${userId}/cycle`,
    headers: authorization(),
    data,
  });
}

export function addPaidLeavesByUserId(userId, data) {
  return request({
    method: "post",
    url: `/users/${userId}/paid_annual_leaves`,
    headers: authorization(),
    data,
  });
}
