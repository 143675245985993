import { authorization, download } from "./core";

export function downloadResumeById(params) {
  return download({
    method: "get",
    url: `/resumes`,
    headers: authorization(),
    params,
  });
}

export function downloadMyResume() {
  return download({
    method: "get",
    url: `/resume`,
    headers: authorization(),
  });
}
