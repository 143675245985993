import { login } from "../../api";

function decode(token) {
  if (typeof token !== "string") return null;
  const encoded = token.split(".");
  if (encoded.length !== 3) return null;
  const payloadString = Buffer.from(encoded[1], "base64").toString();
  return JSON.parse(payloadString);
}

const payload = decode(localStorage.getItem("atago-portal:accessToken"));

function buildUser(payload) {
  return {
    id: payload.sub,
    email: payload.email,
    role: payload.role,
  };
}

const initialState = payload ? { user: buildUser(payload) } : null;

export default {
  namespaced: true,
  state: () => initialState,
  getters: {
    authenticated: (state) => state.user !== undefined && state.user !== null,
    currentUserId: (state) => state.user?.id,
  },
  actions: {
    async login({ commit }, form) {
      try {
        const token = await login(form);
        commit("authenticated", { token, rememberMe: form.rememberMe });
      } catch (e) {
        console.log(e);
        commit("unauthenticated");
        throw e;
      }
    },
    logout({ commit, dispatch }) {
      commit("unauthenticated");
      dispatch("clear", null, { root: true });
    },
  },
  mutations: {
    authenticated(state, { token, rememberMe }) {
      state.user = buildUser(decode(token.access_token));
      if (rememberMe) {
        localStorage.setItem("atago-portal:accessToken", token.access_token);
        localStorage.setItem("atago-portal:refreshToken", token.refresh_token);
      } else {
        sessionStorage.setItem("atago-portal:accessToken", token.access_token);
        sessionStorage.setItem(
          "atago-portal:refreshToken",
          token.refresh_token
        );
      }
    },
    unauthenticated(state) {
      state.user = null;
      localStorage.removeItem("atago-portal:accessToken");
      localStorage.removeItem("atago-portal:refreshToken");
      sessionStorage.removeItem("atago-portal:accessToken");
      sessionStorage.removeItem("atago-portal:refreshToken");
    },
  },
};
