import dayjs from "dayjs";
import Vue from "vue";
import {
  addAdvance,
  addAdvanceSheet,
  addAttendanceSheet,
  addBook,
  addMyCertificate,
  addRental,
  addWorkExperience,
  deactivateBookById,
  downloadMyResume,
  fetchAdvanceSheetById,
  fetchAdvanceSheetList,
  fetchAttendanceSheetById,
  fetchAttendanceSheetList,
  fetchBookAuthors,
  fetchBookById,
  fetchBookList,
  fetchBookPublishers,
  fetchBookTags,
  fetchMe,
  fetchMyProfile,
  fetchRentalList,
  fetchUserNames,
  fetchWorkExperienceList,
  putAttendance,
  removeAdvanceById,
  removeMyCertificate,
  removeWorkExperienceById,
  updateAdvanceById,
  updateAdvanceSheetById,
  updateAttendanceSheetById,
  updateBookById,
  updateMe,
  updateMyCertificate,
  updateMyProfile,
  updateRentalById,
  updateWorkExperienceById,
  uploadBookImage,
} from "../../api";
import { CONTENT } from "../../constants";
import {
  hasAnyOverflows,
  hasAnyOverlappedBreakTimes,
  hasTooFewBreakTimes,
  hasTooMuchBreakTimes,
} from "../../logic/attendance_sheet";

const initialState = () => ({
  user: {},
  time_setting: {},
  profile: {},
  attendance_sheets: {},
  advance_sheets: {},
  work_experiences: {},
  books: {
    books: [],
    total: 0,
  },
  book_form: null,
  usernames: [],
  bookPublishers: [],
  bookAuthors: [],
  bookTags: [],
  rentals: [],
});

const period = (item) =>
  item.period_year.toString().padStart(4, "0") +
  item.period_month.toString().padStart(2, "0");

export default {
  namespaced: true,
  state: () => initialState(),
  getters: {},
  actions: {
    async getMe({ commit }) {
      const responseBody = await fetchMe();
      commit("setUser", responseBody.user);
      commit("setTimeSetting", responseBody.time_setting);
    },
    async updateMe({ commit }, requestBody) {
      await updateMe(requestBody);
      if (requestBody.time_setting) {
        commit("setTimeSetting", requestBody.time_setting);
      }
    },
    async getProfile({ commit }) {
      const responseBody = await fetchMyProfile();
      commit("setProfile", responseBody);
    },
    async updateProfile({ commit }, requestBody) {
      await updateMyProfile(requestBody);
      commit("setProfile", requestBody);
    },
    async addCertificate({ commit }, requestBody) {
      const responseBody = await addMyCertificate(requestBody);
      commit("appendCertificate", responseBody);
    },
    async updateCertificate({ commit }, requestBody) {
      await updateMyCertificate(requestBody.id, requestBody);
      commit("setCertificate", requestBody);
    },
    async removeCertificate({ commit }, certificateId) {
      await removeMyCertificate(certificateId);
      commit("removeCertificate", certificateId);
    },
    async listAttendanceSheets({ commit }) {
      const responseBody = await fetchAttendanceSheetList();
      commit("setAttendanceSheetList", responseBody.attendance_sheets);
    },
    async addAttendanceSheet({ commit, dispatch }, requestBody) {
      const responseBody = await addAttendanceSheet(requestBody);
      const attendanceSheet = {
        id: responseBody.attendance_sheet_id,
        ...requestBody,
        closed_at: null,
        prescribed_work_days: null,
        attendances: [],
      };
      commit("setAttendanceSheet", attendanceSheet);
      const endOfPeriod = dayjs({
        year: requestBody.period_year,
        month: requestBody.period_month - 1,
        date: 1,
      }).endOf("month");
      const yesterday = dayjs().subtract(1, "day");
      const dateEnd = yesterday.isAfter(endOfPeriod) ? endOfPeriod : yesterday;
      await dispatch("fillOut", { sheet: attendanceSheet, dateEnd });
      await dispatch("getAttendanceSheet", attendanceSheet.id);
    },
    async getAttendanceSheet({ commit }, attendanceSheetId) {
      const responseBody = await fetchAttendanceSheetById(attendanceSheetId);
      commit("setAttendanceSheet", responseBody);
    },
    async closeAttendanceSheet({ commit, rootState, dispatch }, sheet) {
      // 月末が休日の場合の埋め
      const today = dayjs();
      const endOfPeriod = dayjs({
        year: sheet.period_year,
        month: sheet.period_month - 1,
      }).endOf("month");

      if (
        (today.isSame(endOfPeriod) &&
          rootState.holidays.has(today.format("YYYY-MM-DD"))) ||
        today.isAfter(endOfPeriod)
      ) {
        const count = await dispatch("fillOut", {
          sheet,
          dateEnd: endOfPeriod,
        });
        if (count > 0) {
          await dispatch("getAttendanceSheet", sheet.id);
          throw {
            status: 409,
            data: {
              detail: `入力されていない日が${count}日ありました。勤怠情報を標準勤務時間で記入したので確認後、再度締めボタンを押してください。`,
            },
          };
        }
      }

      const invalidBreakTimes = [];
      const tooMuchBreakTimes = [];
      const tooFewBreakTimes = [];
      for (const attendance of sheet.attendances) {
        // 休憩時間重複はみだしチェック
        if (
          hasAnyOverlappedBreakTimes(attendance) ||
          hasAnyOverflows(attendance)
        ) {
          invalidBreakTimes.push(attendance.date);
        }

        // 休憩時間過大チェック
        if (hasTooMuchBreakTimes(attendance)) {
          tooMuchBreakTimes.push(attendance.date);
        }

        // 休憩時間過小チェック
        if (hasTooFewBreakTimes(attendance)) {
          tooFewBreakTimes.push(attendance.date);
        }
      }

      let detail = "";

      if (invalidBreakTimes.length > 0) {
        const dates = invalidBreakTimes.join(",");
        detail += `次の日の勤務時間または休憩時間に不整合があります。<br>`;
        detail += `修正して再度締めボタンを押してください。${dates}<br>`;
      }

      if (tooMuchBreakTimes.length > 0) {
        const dates = tooMuchBreakTimes.join(",");
        detail += `次の日の勤務時間は6時間以内ですが休憩時間が入力されています。<br>`;
        detail += `休憩時間を削除して再度締めボタンを押してください。${dates}<br>`;
      }

      if (tooFewBreakTimes.length > 0) {
        const dates = tooFewBreakTimes.join(",");
        detail += `次の日の勤務時間は6時間を超過していますが休憩時間が入力されていません。<br>`;
        detail += `1時間の休憩時間を追加して再度締めボタンを押してください。${dates}<br>`;
      }

      if (detail !== "") {
        throw {
          status: 409,
          data: { detail },
        };
      }

      await updateAttendanceSheetById(sheet.id, { close: true });
      commit("closeAttendanceSheet", sheet.id);
    },
    /**
     * 指定した日付まで勤務表をデフォルトの勤怠情報で埋める
     * @param {ActionContext} context
     * @param {object} payload
     * @param {object} payload.sheet 対象の勤務表
     * @param {dayjs} payload.dateEnd 指定日付
     * @returns
     */
    async fillOut({ state, rootState }, { sheet, dateEnd }) {
      // 埋め開始日付
      let dateStart;
      if (Array.isArray(sheet.attendances) && sheet.attendances.length > 0) {
        dateStart = dayjs(
          sheet.attendances[sheet.attendances.length - 1].date
        ).add(1, "day");
      } else {
        dateStart = dayjs({
          year: sheet.period_year,
          month: sheet.period_month - 1,
          date: 1,
        });
      }

      if (dateStart.isSameOrAfter(dateEnd)) {
        return 0;
      }

      const days = dateEnd.diff(dateStart, "days");

      for (let i = 0; i < days + 1; i++) {
        const currentDate = dateStart.add(i, "day").format("YYYY-MM-DD");
        let form;
        if (rootState.holidays.has(currentDate)) {
          form = {
            content: CONTENT.ABSENCE.value,
          };
        } else {
          form = {
            content: CONTENT.WORK.value,
            clockin_time:
              currentDate + "T" + state.time_setting.work_hour_begin_at,
            clockout_time:
              currentDate + "T" + state.time_setting.work_hour_end_at,
            break_times: [
              {
                id: null,
                begin_at:
                  currentDate + "T" + state.time_setting.break_time_begin_at,
                end_at:
                  currentDate + "T" + state.time_setting.break_time_end_at,
              },
            ],
          };
        }
        await putAttendance(sheet.id, currentDate, form);
      }

      return days;
    },
    async putAttendance({ dispatch, state }, { date, requestBody }) {
      const period = date.format("YYYYMM");

      // 勤務表未作成の場合はここでついでに作成
      if (state.attendance_sheets[period] === undefined) {
        await dispatch("addAttendanceSheet", {
          period_year: period.substr(0, 4),
          period_month: period.substr(4, 2),
        });
      }

      const sheet = state.attendance_sheets[period];
      await dispatch("fillOut", { sheet, dateEnd: date.subtract(1, "day") });
      await putAttendance(sheet.id, date.format("YYYY-MM-DD"), requestBody);
      await dispatch("getAttendanceSheet", sheet.id);
    },
    async clockIn({ dispatch }) {
      await dispatch("putAttendance", {
        date: dayjs(),
        requestBody: {
          content: CONTENT.WORK.value,
          clockin_time: dayjs().local().format("YYYY-MM-DDTHH:mm:ss"),
        },
      });
    },
    async clockOut({ dispatch, state }, clockInTime) {
      const now = dayjs();
      const hasClockedInWithinBreakTime = dayjs(clockInTime).isSameOrAfter(
        dayjs(state.time_setting.break_time_begin_at)
      );
      const hasClockedOutWithinBreakTime = dayjs(
        state.time_setting.break_time_end_at
      ).isSameOrAfter(now);
      await dispatch("putAttendance", {
        date: now,
        requestBody: {
          content: CONTENT.WORK.value,
          clockin_time: clockInTime,
          clockout_time: now.local().format("YYYY-MM-DDTHH:mm:ss"),
          break_times: [
            {
              id: null,
              begin_at: hasClockedInWithinBreakTime
                ? clockInTime
                : now.format("YYYY-MM-DD") +
                  "T" +
                  state.time_setting.break_time_begin_at,
              end_at: hasClockedOutWithinBreakTime
                ? now.local().format("YYYY-MM-DDTHH:mm:ss")
                : now.format("YYYY-MM-DD") +
                  "T" +
                  state.time_setting.break_time_end_at,
            },
          ],
        },
      });
    },
    async takeLeave({ dispatch }) {
      await dispatch("putAttendance", {
        date: dayjs(),
        requestBody: {
          content: CONTENT.PAID_DAY_OFF.value,
        },
      });
    },
    async listAdvanceSheets({ commit }) {
      const responseBody = await fetchAdvanceSheetList();
      commit("setAdvanceSheetList", responseBody.advance_sheets);
    },
    async addAdvanceSheet({ commit }, requestBody) {
      const responseBody = await addAdvanceSheet(requestBody);
      const advanceSheet = {
        id: responseBody.advance_sheet_id,
        ...requestBody,
        closed_at: null,
        advances: [],
      };
      commit("setAdvanceSheet", advanceSheet);
    },
    async getAdvanceSheet({ commit }, advanceSheetId) {
      const responseBody = await fetchAdvanceSheetById(advanceSheetId);
      commit("setAdvanceSheet", responseBody);
    },
    async closeAdvanceSheet({ commit, dispatch, state }, period) {
      if (state.advance_sheets[period] === undefined) {
        await dispatch("addAdvanceSheet", {
          period_year: period.substr(0, 4),
          period_month: period.substr(4, 2),
        });
      }
      const advanceSheetId = state.advance_sheets[period].id;
      await updateAdvanceSheetById(advanceSheetId, { close: true });
      commit("closeAdvanceSheet", advanceSheetId);
    },
    async addAdvance({ dispatch, state }, { period, requestBody }) {
      if (state.advance_sheets[period] === undefined) {
        await dispatch("addAdvanceSheet", {
          period_year: period.substr(0, 4),
          period_month: period.substr(4, 2),
        });
      }
      const advanceSheetId = state.advance_sheets[period].id;
      await addAdvance(advanceSheetId, requestBody);
      await dispatch("getAdvanceSheet", advanceSheetId);
    },
    async updateAdvance({ commit }, { period, advanceId, requestBody }) {
      await updateAdvanceById(advanceId, requestBody);
      commit("setAdvance", { period, id: advanceId, ...requestBody });
    },
    async removeAdvance({ commit }, { period, advanceId }) {
      await removeAdvanceById(advanceId);
      commit("removeAdvance", { period, id: advanceId });
    },
    async listWorkExperiences({ commit }) {
      const responseBody = await fetchWorkExperienceList();
      commit("setWorkExperienceList", responseBody.work_experiences);
    },
    async addWorkExperience({ commit }, requestBody) {
      const responseBody = await addWorkExperience(requestBody);
      commit("addWorkExperience", responseBody);
    },
    async updateWorkExperience({ commit }, { workExperienceId, requestBody }) {
      await updateWorkExperienceById(workExperienceId, requestBody);
      commit("setWorkExperience", { id: workExperienceId, ...requestBody });
    },
    async removeWorkExperience({ commit }, workExperienceId) {
      await removeWorkExperienceById(workExperienceId);
      commit("removeWorkExperience", workExperienceId);
    },
    async downloadResume(_context) {
      await downloadMyResume();
    },
    async enterMenu({ dispatch, state }) {
      await dispatch("getMe");
      await dispatch("listAttendanceSheets");
      const sheet = state.attendance_sheets[dayjs().format("YYYYMM")];
      if (sheet !== undefined) {
        await dispatch("getAttendanceSheet", sheet.id);
      }
    },
    async enterAttendanceSheet({ dispatch, state }, { period }) {
      await dispatch("getMe");
      await dispatch("listAttendanceSheets");
      const sheet = state.attendance_sheets[period];
      if (sheet === undefined) {
        return;
      }

      await dispatch("getAttendanceSheet", sheet.id);
    },
    async enterAdvanceSheet({ dispatch, state }, { period }) {
      await dispatch("listAdvanceSheets");
      const sheet = state.advance_sheets[period];
      if (sheet === undefined) {
        return;
      }

      await dispatch("getAdvanceSheet", sheet.id);
    },
    async listBooks({ commit }, params) {
      const responseBody = await fetchBookList(params);
      commit("setBookList", responseBody);
    },
    async getBook({ commit }, bookId) {
      const responseBody = await fetchBookById(bookId);
      commit("setBook", responseBody);
    },
    async saveBook({ commit }, requestBody) {
      const { id, file, ...data } = requestBody;
      let result;
      if (id === undefined || id === null) {
        result = await addBook(data);
        commit("addBook", result);
      } else {
        await updateBookById(id, data);
        result = { id };
      }

      if (file) {
        const uploadFile = new FormData();
        uploadFile.append("file", file);
        await uploadBookImage(result.id, uploadFile);
      }
    },
    async listUserName({ commit }) {
      const responseBody = await fetchUserNames();
      commit("setUserNames", responseBody.usernames);
    },
    async listBookPublisher({ commit }) {
      const responseBody = await fetchBookPublishers();
      commit("setBookPublishers", responseBody.publishers);
    },
    async listBookAuthor({ commit }) {
      const responseBody = await fetchBookAuthors();
      commit("setBookAuthors", responseBody.authors);
    },
    async listBookTag({ commit }) {
      const responseBody = await fetchBookTags();
      commit("setBookTags", responseBody.tags);
    },
    async deactivateBook({ commit }, bookId) {
      await deactivateBookById(bookId, {
        expired_at: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      });
      commit("removeBook", bookId);
    },
    async borrowBook({ commit }, { bookId, storedBookId }) {
      const responseBody = await addRental({ stored_book_id: storedBookId });
      commit("setBookRental", { bookId, rental: responseBody });
    },
    async listRental({ commit }) {
      const responseBody = await fetchRentalList();
      commit("setRentalList", responseBody);
    },
    async returnBook({ commit }, { rentalId }) {
      const returned_at = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      await updateRentalById(rentalId, { returned_at });
      commit("setRental", { rentalId, returned_at });
    },
  },
  mutations: {
    clear(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setUser(state, user) {
      for (const prop in user) {
        Vue.set(state.user, prop, user[prop]);
      }
    },
    setProfile(state, profile) {
      for (const prop in profile) {
        Vue.set(state.profile, prop, profile[prop]);
      }
    },
    setTimeSetting(state, time_setting) {
      for (const prop in time_setting) {
        Vue.set(state.time_setting, prop, time_setting[prop]);
      }
    },
    appendCertificate(state, certificate) {
      if (state.profile.certificates === undefined) {
        Vue.set(state.profile, "certificates", []);
      }
      state.profile.certificates.push(certificate);
    },
    setCertificate(state, certificate) {
      const index = state.profile.certificates.findIndex(
        (v) => v.id === certificate.id
      );
      Vue.set(state.profile.certificates, index, certificate);
    },
    removeCertificate(state, certificateId) {
      const index = state.profile.certificates.findIndex(
        (v) => v.id === certificateId
      );
      state.profile.certificates.splice(index, 1);
    },
    setAttendanceSheetList(state, attendance_sheets) {
      for (const attendanceSheet of attendance_sheets) {
        Vue.set(
          state.attendance_sheets,
          period(attendanceSheet),
          attendanceSheet
        );
      }
    },
    setAttendanceSheet(state, attendanceSheet) {
      attendanceSheet.attendances.sort((a, b) => a.date.localeCompare(b.date));
      Vue.set(
        state.attendance_sheets,
        period(attendanceSheet),
        attendanceSheet
      );
    },
    closeAttendanceSheet(state, attendanceSheetId) {
      const attendanceSheet = Object.values(state.attendance_sheets).find(
        (v) => v.id === attendanceSheetId
      );
      attendanceSheet.closed_at = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    },
    setAdvanceSheetList(state, advance_sheets) {
      for (const advanceSheet of advance_sheets) {
        Vue.set(state.advance_sheets, period(advanceSheet), advanceSheet);
      }
    },
    setAdvanceSheet(state, advanceSheet) {
      advanceSheet.advances.sort((a, b) => a.date.localeCompare(b.date));
      Vue.set(state.advance_sheets, period(advanceSheet), advanceSheet);
    },
    closeAdvanceSheet(state, advanceSheetId) {
      const advanceSheet = Object.values(state.advance_sheets).find(
        (v) => v.id === advanceSheetId
      );
      advanceSheet.closed_at = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    },
    setAdvance(state, { period, ...advance }) {
      const index = state.advance_sheets[period].advances.findIndex(
        (v) => v.id === advance.id
      );
      if (index === -1) {
        state.advance_sheets[period].advances.push(advance);
      } else {
        for (const prop in advance) {
          Vue.set(
            state.advance_sheets[period].advances[index],
            prop,
            advance[prop]
          );
        }
      }
    },
    removeAdvance(state, { period, id }) {
      const index = state.advance_sheets[period].advances.findIndex(
        (v) => v.id === id
      );
      if (index === -1) {
        return;
      }
      state.advance_sheets[period].advances.splice(index, 1);
    },
    setWorkExperienceList(state, work_experiences) {
      for (const workExperience of work_experiences) {
        Vue.set(state.work_experiences, workExperience.id, workExperience);
      }
    },
    addWorkExperience(state, workExperience) {
      Vue.set(state.work_experiences, workExperience.id, workExperience);
    },
    setWorkExperience(state, workExperience) {
      for (const prop in workExperience) {
        Vue.set(
          state.work_experiences[workExperience.id],
          prop,
          workExperience[prop]
        );
      }
    },
    removeWorkExperience(state, workExperienceId) {
      Vue.delete(state.work_experiences, workExperienceId);
    },
    setBookList(state, books) {
      state.books = books;
    },
    clearBookList(state) {
      state.books = {
        books: [],
        total: 0,
      };
    },
    setBook(state, book) {
      state.book_form = book;
    },
    addBook(state, book) {
      state.books.books.unshift(book);
      state.books.total += 1;
    },
    removeBook(state, bookId) {
      const index = state.books.books.findIndex((v) => +v.id === +bookId);
      if (index !== -1) {
        state.books.books.splice(index, 1);
        state.books.total -= 1;
      }
    },
    setBookRental(state, { bookId, rental }) {
      const bookIndex = state.books.books.findIndex((v) => +v.id === +bookId);
      if (bookIndex !== -1) {
        const index = state.books.books[bookIndex].stored_books.findIndex(
          (v) => +v.id === +rental.stored_book_id
        );
        if (index !== -1) {
          state.books.books[bookIndex].stored_books[index].rental = rental;
        }
      }

      const index = state.book_form.stored_books.findIndex(
        (v) => +v.id === +rental.stored_book_id
      );
      if (index !== -1) {
        state.book_form.stored_books[index].rental = rental;
      }
    },
    setUserNames(state, items) {
      state.usernames = items;
    },
    setBookPublishers(state, items) {
      state.bookPublishers = items;
    },
    setBookAuthors(state, items) {
      state.bookAuthors = items;
    },
    setBookTags(state, items) {
      state.bookTags = items;
    },
    setRentalList(state, { rentals }) {
      state.rentals = rentals;
    },
    setRental(state, { rentalId, returned_at }) {
      const index = state.rentals.findIndex((v) => +v.id === +rentalId);
      if (index !== -1) {
        state.rentals[index].returned_at = returned_at;
      }
    },
  },
};
