import Vue from "vue";
import {
  addPaidLeavesByUserId,
  addPassCycleByUserId,
  addUser,
  deactivateUserById,
  downloadAdvanceSheetXlsx,
  downloadAttendanceSheetById,
  downloadAttendanceSheetXlsx,
  downloadResumeById,
  fetchAdvanceSheetById,
  fetchAllUsers,
  fetchAttendanceSheetById,
  fetchProfileById,
  fetchUserById,
  fetchUserList,
  grantPaidAnnualLeave,
  updateAdvanceSheetById,
  updateAttendanceSheetById,
  updatePassCycleByUserId,
} from "../../api";

const initialState = () => ({
  users: [],
  profiles: {},
  time_settings: {},
});

export default {
  namespaced: true,
  state: () => initialState(),
  getters: {},
  actions: {
    async listUsers({ commit }) {
      try {
        const responseBody = await fetchUserList();
        commit("setUserList", responseBody.users);
      } catch (e) {
        commit("clearUserList");
        throw e;
      }
    },
    async allUsers({ commit }) {
      try {
        const responseBody = await fetchAllUsers();
        commit("setAllUsers", responseBody);
      } catch (e) {
        commit("clearUserList");
        throw e;
      }
    },
    async addUser({ dispatch }, requestBody) {
      const responseBody = await addUser(requestBody);
      await dispatch("getProfile", responseBody.profile_id);
      await dispatch("getUser", responseBody.user_id);
    },
    async getUser({ commit }, userId) {
      const responseBody = await fetchUserById(userId);
      commit("setUser", responseBody.user);
      commit("setTimeSetting", responseBody.time_setting);
    },
    async deactivateUser({ commit }, { userId, requestBody }) {
      await deactivateUserById(userId, requestBody);
      commit("setUserExpiredAt", { id: userId, ...requestBody });
    },
    async addPassCycle({ dispatch }, { userId, requestBody }) {
      await addPassCycleByUserId(userId, requestBody);
      await dispatch("getUser", userId);
    },
    async updatePassCycle({ dispatch }, { userId, requestBody }) {
      await updatePassCycleByUserId(userId, requestBody);
      await dispatch("getUser", userId);
    },
    async changePaidAnnualLeaveStatus({ dispatch }, { userId, requestBody }) {
      await addPaidLeavesByUserId(userId, requestBody);
      await dispatch("getUser", userId);
    },
    async grantPaidAnnualLeave({ dispatch }, requestBody) {
      await grantPaidAnnualLeave(requestBody);
      await dispatch("allUsers");
    },
    async getProfile({ commit }, profileId) {
      const responseBody = await fetchProfileById(profileId);
      commit("setProfile", responseBody);
    },
    async getAdvanceSheet({ commit }, { userId, advanceSheetId }) {
      const responseBody = await fetchAdvanceSheetById(advanceSheetId);
      commit("setAdvanceSheet", { userId, advanceSheet: responseBody });
    },

    async getAttendanceSheet({ commit }, { userId, attendanceSheetId }) {
      const responseBody = await fetchAttendanceSheetById(attendanceSheetId);
      commit("setAttendanceSheet", { userId, attendanceSheet: responseBody });
    },
    async downloadAttendanceSheetXlsx(_context, params) {
      await downloadAttendanceSheetXlsx(params);
    },
    async downloadAttendanceSheetById(_context, attendanceSheetId) {
      await downloadAttendanceSheetById(attendanceSheetId);
    },
    async downloadAdvanceSheetXlsx(_context, params) {
      await downloadAdvanceSheetXlsx(params);
    },
    async downloadResume(_context, resume_ids) {
      await downloadResumeById({ resume_ids });
    },
    async cancelAttendanceSheetClosing(
      { commit },
      { userId, attendanceSheetId }
    ) {
      await updateAttendanceSheetById(attendanceSheetId, { close: false });
      commit("cancelUserAttendanceSheetClosing", { userId, attendanceSheetId });
    },
    async cancelAdvanceSheetClosing({ commit }, { userId, advanceSheetId }) {
      await updateAdvanceSheetById(advanceSheetId, { close: false });
      commit("cancelUserAdvanceSheetClosing", { userId, advanceSheetId });
    },
    async enterAttendanceSheet({ dispatch, state }, { period, userId }) {
      if (state.users.length === 0) {
        await dispatch("listUsers");
      }
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      if (userIndex === -1) {
        throw new Error("指定されたユーザーが存在しません。");
      }

      const index = state.users[userIndex].attendance_sheets.findIndex(
        (v) => v.period_year + v.period_month === period
      );
      if (index === -1) {
        throw new Error("指定された年月の勤務表が存在しません。");
      }

      const sheet = state.users[userIndex].attendance_sheets[index];
      await dispatch("getAttendanceSheet", {
        userId,
        attendanceSheetId: sheet.id,
      });
    },
    async enterAdvanceSheet({ dispatch, state }, { period, userId }) {
      if (state.users.length === 0) {
        await dispatch("listUsers");
      }
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      if (userIndex === -1) {
        throw new Error("指定されたユーザーが存在しません。");
      }

      const index = state.users[userIndex].advance_sheets.findIndex(
        (v) => v.period_year + v.period_month === period
      );
      if (index === -1) {
        throw new Error("指定された年月の勤務表が存在しません。");
      }

      const sheet = state.users[userIndex].advance_sheets[index];
      await dispatch("getAdvanceSheet", {
        userId,
        advanceSheetId: sheet.id,
      });
    },
  },
  mutations: {
    clear(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setUserList(state, users) {
      state.users = users;
    },
    clearUserList(state) {
      Vue.set(state, "users", []);
    },
    setUser(state, user) {
      const userIndex = state.users.findIndex((v) => +v.id === +user.id);
      if (userIndex !== -1) {
        Vue.set(state.users, userIndex, { ...state.users[userIndex], ...user });
      } else {
        state.users.push(user);
      }
    },
    setUserExpiredAt(state, { id, expired_at }) {
      const userIndex = state.users.findIndex((v) => +v.id === +id);
      state.users[userIndex].expired_at = expired_at;
    },
    setProfile(state, profile) {
      const index = state.profiles.findIndex((v) => +v.id === +profile.id);
      if (index !== -1) {
        Vue.set(state.profiles, index, {
          ...state.profiles[index],
          ...profile,
        });
      } else {
        state.profiles.push(profile);
      }
    },
    setTimeSetting(state, timeSetting) {
      const index = state.time_settings.findIndex(
        (v) => +v.id === +timeSetting.id
      );
      if (index !== -1) {
        Vue.set(state.time_settings, index, {
          ...state.time_settings[index],
          ...timeSetting,
        });
      } else {
        state.time_settings.push(timeSetting);
      }
    },
    setAttendanceSheet(state, { userId, attendanceSheet }) {
      attendanceSheet.attendances.sort((a, b) => a.date.localeCompare(b.date));
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      const index = state.users[userIndex].attendance_sheets.findIndex(
        (v) => +v.id === +attendanceSheet.id
      );
      Vue.set(state.users[userIndex].attendance_sheets, index, attendanceSheet);
    },
    setAdvanceSheet(state, { userId, advanceSheet }) {
      advanceSheet.advances.sort((a, b) => a.date.localeCompare(b.date));
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      const index = state.users[userIndex].advance_sheets.findIndex(
        (v) => +v.id === +advanceSheet.id
      );
      Vue.set(state.users[userIndex].advance_sheets, index, advanceSheet);
    },
    cancelUserAttendanceSheetClosing(state, { userId, attendanceSheetId }) {
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      const index = state.users[userIndex].attendance_sheets.findIndex(
        (v) => +v.id === +attendanceSheetId
      );

      state.users[userIndex].attendance_sheets[index].closed_at = null;
    },
    cancelUserAdvanceSheetClosing(state, { userId, advanceSheetId }) {
      const userIndex = state.users.findIndex((v) => +v.id === +userId);
      const index = state.users[userIndex].advance_sheets.findIndex(
        (v) => +v.id === +advanceSheetId
      );
      state.users[userIndex].advance_sheets[index].closed_at = null;
    },
    setAllUsers(state, { users, profiles, time_settings }) {
      state.users = users;
      state.profiles = profiles;
      state.time_settings = time_settings;
    },
  },
};
