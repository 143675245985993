<template>
  <div class="container is-fluid">
    <div class="is-size-4 has-text-centered mb-2">{{ today }}</div>
    <div class="columns is-mobile" v-if="workingToday == 'not_yet_in'">
      <div class="column">
        <b-button
          type="is-success is-light"
          icon-left="login-variant"
          expanded
          @click="clockIn"
          size="is-large"
          class="big-button"
          :loading="storing"
        >
          出勤
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-danger is-light"
          :icon-left="dayOffIcon"
          expanded
          @click="takeLeave"
          :disabled="isHoliday"
          size="is-large"
          class="big-button"
          :loading="storing"
        >
          年休
        </b-button>
      </div>
    </div>
    <div class="columns is-mobile mt-1" v-else-if="workingToday == 'in'">
      <div class="column">
        <b-button
          type="is-warning is-light"
          icon-left="logout-variant"
          expanded
          @click="clockOut"
          class="big-button"
          size="is-large"
          :loading="storing"
        >
          退勤
        </b-button>
      </div>
    </div>
    <div class="columns">
      <b-menu class="column is-4">
        <b-menu-list label="My Page">
          <b-menu-item
            icon="clock"
            label="勤務表"
            tag="router-link"
            :to="{ name: 'latestAttendanceSheet' }"
          ></b-menu-item>
          <b-menu-item
            icon="cash-100"
            label="立替金申請"
            tag="router-link"
            :to="{ name: 'latestAdvanceSheet' }"
          ></b-menu-item>
          <b-menu-item
            icon="card-account-details"
            label="経歴書"
            tag="router-link"
            :to="{ name: 'resume' }"
          ></b-menu-item>
          <b-menu-item icon="bookshelf" label="書籍管理" expanded>
            <b-menu-item
              icon="book-search"
              label="検索"
              tag="router-link"
              :to="{ name: 'books' }"
            ></b-menu-item>
            <b-menu-item
              icon="book-open-variant"
              label="貸出履歴"
              tag="router-link"
              :to="{ name: 'rentals' }"
            ></b-menu-item>
          </b-menu-item>
          <b-menu-item
            icon="cog"
            label="設定"
            tag="router-link"
            :to="{ name: 'settings' }"
          ></b-menu-item>
        </b-menu-list>
        <b-menu-list label="Admin" v-if="isAdmin">
          <b-menu-item
            icon="account-box-multiple"
            label="社員一覧"
            tag="router-link"
            :to="{ name: 'users' }"
          ></b-menu-item>
        </b-menu-list>
        <b-menu-list label="Actions">
          <b-menu-item
            icon="logout"
            label="ログアウト"
            @click="logout"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>

      <div class="column is-8">
        <b-message
          :type="paidLeaves.cleared ? 'is-success' : 'is-warning'"
          :closable="false"
          v-if="paidLeaves !== undefined"
        >
          <template #header>
            <b-icon
              :icon="paidLeaves.cleared ? 'check' : 'alert'"
              size="is-small"
            />
            年次有給休暇取得状況
          </template>
          <dl>
            <dt>残日数:</dt>
            <dd>{{ paidLeaves.balance }}<small>日</small></dd>
            <dt>消化日数:</dt>
            <dd>
              {{ paidLeaves.consumed
              }}<small>日 ({{ paidLeaves.expiredOn }}まで)</small>
            </dd>
            <dt>前回付与:</dt>
            <dd>
              {{ paidLeaves.prevGrantedDays
              }}<small>日 ({{ paidLeaves.prevGrantedOn }})</small>
            </dd>
            <dt>次回付与:</dt>
            <dd>
              {{ paidLeaves.nextGrantedDays
              }}<small>日 ({{ paidLeaves.nextGrantedOn }})</small>
            </dd>
          </dl>
        </b-message>
        <b-message
          type="is-primary"
          title="定期代振込サイクル"
          :closable="false"
          v-if="passCycle !== undefined"
        >
          <dl>
            <dt>前回実績:</dt>
            <dd v-if="passCycle.prevDate === undefined">なし</dd>
            <dd v-else>
              {{ passCycle.prevDate }}
              <small>({{ passCycle.prevPeriod }} ~)</small>
            </dd>
            <dt>次回予定:</dt>
            <dd v-if="passCycle.nextDate === undefined">なし</dd>
            <dd v-else>
              {{ passCycle.nextDate }}
              <small>({{ passCycle.nextPeriod }} ~)</small>
            </dd>
          </dl>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { CONTENT, ROLE } from "../constants";
import fetchMixin from "../fetch-mixin";
import storeMixin from "../store-mixin";
import store from "../store";

const DAY_OFF_ICONS = [
  "fish",
  "golf",
  "palm-tree",
  "gamepad-square",
  "cat",
  "baby-carriage",
  "dog",
  "soccer",
  "glass-wine",
  "car-sports",
  "lamp",
];

export default {
  name: "Menu",
  components: {},
  mixins: [fetchMixin, storeMixin],
  data: function () {
    return {
      loading: false,
    };
  },
  computed: {
    today() {
      return dayjs().format("ll(ddd)");
    },
    attendanceSheetThisMonth() {
      const period = dayjs().format("YYYYMM");
      return this.$store.state.my.attendance_sheets[period];
    },
    attendanceToday() {
      const now = dayjs();
      return this.attendanceSheetThisMonth?.attendances?.find((v) =>
        now.isSame(v.date, "date")
      );
    },
    workingToday() {
      if (this.attendanceToday === undefined) {
        return "not_yet_in";
      } else if (
        this.attendanceToday.clockout_time === null &&
        this.attendanceToday.content === CONTENT.WORK.value
      ) {
        return "in";
      } else {
        return "out";
      }
    },
    isAdmin() {
      return this.$store.state.auth.user.role === ROLE.ADMIN.value;
    },
    isHoliday() {
      return this.$store.state.holidays.has(dayjs().format("YYYY-MM-DD"));
    },
    dayOffIcon() {
      const key = dayjs().date() % DAY_OFF_ICONS.length;
      return DAY_OFF_ICONS[key];
    },
    paidLeaves() {
      const pal = this.$store.state.my.user.paid_annual_leave_history;
      if (pal === undefined) {
        return undefined;
      }
      return {
        balance: pal.days_balance || 0,
        consumed: -pal.days_consumed || 0,
        prevGrantedOn:
          pal.prev_grant?.date === undefined
            ? "未"
            : dayjs(pal.prev_grant?.date).format("YYYY/MM/DD"),
        nextGrantedOn: dayjs(pal.next_grant.date).format("YYYY/MM/DD"),
        prevGrantedDays:
          pal.prev_grant?.days === undefined ? 0 : pal.prev_grant?.days,
        nextGrantedDays: pal.next_grant.days,
        expiredOn: dayjs(pal.next_grant.date)
          .subtract(1, "day")
          .format("YYYY/MM/DD"),
        cleared: pal.days_consumed < -5,
      };
    },
    passCycle() {
      const cycle = this.$store.state.my.user.pass_history;
      if (cycle === undefined) {
        return undefined;
      }

      return {
        prevDate:
          cycle.prev?.transferred_on === undefined
            ? undefined
            : dayjs(cycle.prev?.transferred_on).format("YYYY/MM/DD"),
        nextDate:
          cycle.next?.transferred_on === undefined
            ? undefined
            : dayjs(cycle.next?.transferred_on).format("YYYY/MM/DD"),
        prevPeriod:
          cycle.prev?.period_begin_on === undefined
            ? "なし"
            : dayjs(cycle.prev?.period_begin_on).format("YYYY/MM/DD"),
        nextPeriod:
          cycle.next?.period_begin_on === undefined
            ? "なし"
            : dayjs(cycle.next?.period_begin_on).format("YYYY/MM/DD"),
      };
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
    async clockIn() {
      this.$store.commit("setLoading", true);
      await this.store("my/clockIn");
      this.$store.commit("setLoading", false);
    },
    async clockOut() {
      await this.store("my/clockOut", this.attendanceToday.clockin_time);
    },
    async takeLeave() {
      this.$store.commit("setLoading", true);
      await this.store("my/takeLeave");
      this.$store.commit("setLoading", false);
    },
  },
  async beforeRouteEnter(_route, _redirect, next) {
    try {
      store.commit("setLoading", true);
      await store.dispatch("my/enterMenu");
      store.commit("setLoading", false);
      store.commit("clearError");
      next();
    } catch (e) {
      console.log("Menu");
      console.log(e);
      store.commit("setError", e.message);
      store.commit("setLoading", false);
      if (e.status && e.status === 401) {
        next({ name: "login" });
      }
    }
  },
};
</script>

<style>
.big-button {
  height: 6rem;
}
</style>
