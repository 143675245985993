import { authorization, download, request } from "./core";

export function fetchAttendanceSheetList() {
  return request({
    method: "get",
    url: `/attendance_sheets`,
    headers: authorization(),
  });
}

export function downloadAttendanceSheetXlsx(params) {
  return download({
    method: "get",
    url: `/attendance_sheets/all`,
    headers: authorization(),
    params,
  });
}

export function addAttendanceSheet(data) {
  return request({
    method: "post",
    url: `/attendance_sheets`,
    headers: authorization(),
    data,
  });
}

export function fetchAttendanceSheetById(attendanceSheetId) {
  return request({
    method: "get",
    url: `/attendance_sheets/${attendanceSheetId}`,
    headers: authorization(),
  });
}

export function downloadAttendanceSheetById(attendanceSheetId) {
  return download({
    method: "get",
    url: `/attendance_sheets/${attendanceSheetId}`,
    headers: authorization(),
    params: {
      format: "xlsx",
    },
  });
}

export function updateAttendanceSheetById(attendanceSheetId, data) {
  return request({
    method: "patch",
    url: `/attendance_sheets/${attendanceSheetId}`,
    headers: authorization(),
    data,
  });
}

export function putAttendance(attendanceSheetId, date, data) {
  return request({
    method: "put",
    url: `/attendance_sheets/${attendanceSheetId}/${date}`,
    headers: authorization(),
    data,
  });
}
