import axios from "axios";
import qs from "qs";

const client = axios.create({
  baseURL: `${process.env.BASE_URI}/api/v1`,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

export function authorization() {
  return {
    Authorization: `Bearer ${
      localStorage.getItem("atago-portal:accessToken") ??
      sessionStorage.getItem("atago-portal:accessToken")
    }`,
  };
}

export async function request(config) {
  return callAPI(config, request);
}

export async function download(config) {
  const callback = (response) => {
    const matched = /filename="(.+)"/.exec(
      response.headers["content-disposition"]
    );
    const filename = decodeURIComponent(matched[1]);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };
  return callAPI({ ...config, responseType: "blob" }, download, callback);
}

async function callAPI(config, retryFunction, after) {
  try {
    const response = await client.request(config);
    if (typeof after === "function") {
      after(response);
    }
    return response?.data;
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 401) {
        const responseData = await refreshAndRetry(
          config,
          e.response,
          retryFunction
        );
        return responseData;
      }
      throw e.response;
    } else {
      throw { status: 400, data: { message: e.message } };
    }
  }
}

async function refreshAndRetry(config, cause, retryFunction = request) {
  const tokenInLocalStorage = localStorage.getItem("atago-portal:refreshToken");
  const tokenInSessionStorage = sessionStorage.getItem(
    "atago-portal:refreshToken"
  );
  const refreshToken = tokenInLocalStorage ?? tokenInSessionStorage;
  if (refreshToken === null) {
    throw cause;
  }
  try {
    const response = await client.request({
      method: "post",
      url: "/auth/refresh",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
    });
    if (tokenInLocalStorage) {
      localStorage.setItem(
        "atago-portal:accessToken",
        response.data.access_token
      );
      localStorage.setItem(
        "atago-portal:refreshToken",
        response.data.refresh_token
      );
    } else {
      sessionStorage.setItem(
        "atago-portal:accessToken",
        response.data.access_token
      );
      sessionStorage.setItem(
        "atago-portal:refreshToken",
        response.data.refresh_token
      );
    }
  } catch (e) {
    console.log("Failed to refresh access token with refresh token.");
    console.log(e);
    localStorage.removeItem("atago-portal:accessToken");
    localStorage.removeItem("atago-portal:refreshToken");
    sessionStorage.removeItem("atago-portal:accessToken");
    sessionStorage.removeItem("atago-portal:refreshToken");
    throw {
      status: 401,
      data: {
        message: "アクセストークンが失効しました。再ログインしてください。",
      },
    };
  }
  console.log("Succeeded. Retrying...");
  return await retryFunction({ ...config, headers: authorization() });
}

export async function login({ email, password }) {
  try {
    const response = await client.request({
      method: "post",
      url: "/auth/token",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify({ username: email + "@atago.cc", password }),
    });
    return response.data;
  } catch (e) {
    console.log(e);
    if (e.response) {
      throw e.response;
    } else {
      throw { status: 400, data: { message: e.message } };
    }
  }
}
