import { authorization, request } from "./core";

export function fetchProfileById(profileId) {
  return request({
    method: "get",
    url: `/profiles/${profileId}`,
    headers: authorization(),
  });
}

export function fetchMyProfile() {
  return request({
    method: "get",
    url: `/profile`,
    headers: authorization(),
  });
}

export function updateMyProfile(data) {
  return request({
    method: "patch",
    url: `/profile`,
    headers: authorization(),
    data,
  });
}

export function addMyCertificate(data) {
  return request({
    method: "post",
    url: `/profile/certificates`,
    headers: authorization(),
    data,
  });
}

export function updateMyCertificate(certificateId, data) {
  return request({
    method: "patch",
    url: `/profile/certificates/${certificateId}`,
    headers: authorization(),
    data,
  });
}

export function removeMyCertificate(certificateId) {
  return request({
    method: "delete",
    url: `/profile/certificates/${certificateId}`,
    headers: authorization(),
  });
}
